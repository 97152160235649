<script>
import TablePage from '../../../../../components/table_page';
import request from '../../../../../utils/request';

export default {
  name: 'table-component',
  extends: TablePage,
  data() {
    return {
      requestUrl: '',
      formConfig: {},
    };
  },
  methods: {

  },
  created() {
    this.getConfigList('sfaCenter_visitManage_visitCompletionProgress');
  },

};
</script>
